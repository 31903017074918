import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from "@material-ui/core/";
import Container from "@material-ui/core/Container";

export default function Authors() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Yazarlar
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.cardRoot}>
            <CardHeader
              className={classes.cardHeaderRight}
              title="Hakan Bağcı"
              subheader=""
            />
            <CardMedia
              className={classes.media}
              image="/images/hakan-bagci.png"
              title="Hakan Bağcı"
            />
            <CardContent>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                align="right"
              >
                Hakan Bağcı 1977 yılında Almanya’da doğdu. İlköğretim ve
                ortaöğretimini Eskişehir’de tamamlamıştır. İstatistik Bölümü ile
                üniversite hayatına başlayan Bağcı, bölüm tercihini değiştirerek
                Marmara Üniversitesi Atatürk Eğitim Fakültesi Müzik Bölümü’ne
                girmiştir. Yüksek Lisans ve Doktora çalışmalarını aynı kurumun
                Eğitim Bilimleri Enstitüsü’nde tamamlamıştır. Anadolu
                Üniversitesi Çoksesli Gençlik Korosu, Marmara Üniversitesi
                Çoksesli Korosu ve TRT İstanbul Radyosu Çoksesli Gençlik
                Korosu’nda koristlik yapmıştır. Gülsevin Doğanay, Yücel Elmas,
                Gökçen Koray, Esna Mutlu, Sedat Öztoprak ve Paolo Villa ile
                çalışmıştır. Özcan Özbek ile solfej ve armoni, Sarper Özsan,
                Mari Barsamyan ve Hasan Uçarsu ile müzik teorisi, Hilal Dicle
                ile piyano, Dilek Batıbay, Ebru Bulur ve Mari Barsamyan ile
                eşlik çalışmaları yapmıştır. Marmara Üniversitesi Atatürk Eğitim
                Fakültesi Güzel Sanatlar Eğitimi Bölümü Müzik Öğretmenliği
                Anabilim Dalı’nda görev almıştır. Halen Kocaeli Üniversitesi
                Güzel Sanatlar Fakültesi’nde öğretim üyesi olarak çalışmaktadır.
                Solfej, Armoni, Koro, Piyano, Bilgisayar Destekli Müzik Tasarımı
                ve Bilimsel Araştırma Yöntem ve Teknikleri dersleri vermektedir.
                Kocaeli Üniversitesi Müzik Bölümü Korosu’nda ve Koro Frekans’ta
                yardımcı şef olarak, Küçük Nüanslar Korosu’nda şef ve piyanist
                olarak çalışmaktadır. Yayımlanmış eşlik çalışmaları ve Çocuk
                Şarkıları Yarışması’nda ödüllü eşlik çalışması mevcuttur.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.root}>
            <CardHeader
              title="Cengiz Ünal"
              subheader=""
              className={classes.cardHeaderLeft}
            />

            <CardMedia
              className={classes.media}
              image="/images/cengiz-unal.png"
              title="Cengiz Ünal"
            />
            <CardContent>
              <Typography variant="body1" color="textSecondary" component="p">
                Cengiz Ünal 1972 yılında Antalya’da doğdu. Müzik öğrenimini
                Marmara Üniversitesi Atatürk Eğitim Fakültesi’nde tamamladı.
                İlknur Okatan ile şan, Hilal Dicle ile piyano ve Yücel Elmas ile
                koro çalışmalarını gerçekleştirdi. İlk armoni çalışmalarına 4
                sesli koro notalarını Raif Gülcan ile birlikte inceleyerek
                başladı. İlk şeflik deneyimini de 1992 yılında yaptı. 7 yıl
                süreyle Ruhi Su Dostlar Korosunu yönetti ve koro için türkü
                düzenlemeleri yaptı. Aynı yıllarda “Direnen Mızıkacılar” grubunu
                kurdu ve 16 yıl boyunca bu grup ile konserler verdi. 2003
                yılında kompozitörlüğü ve vokalistliğini de yaptığı Türkiye’nin
                ilk akapella grubu olan Vokaliz grubunu kurdu ve bu grubun 2
                albümünün prodüktörlüğünü yaptı. Birçok vokal ve senfoni için
                düzenlemeleri de bu dönemde oluşturulmuştur. Prodüktörlük
                mesleği ile de aynı yıllarda tanışan ve çok sayıda yapımcının ve
                sanatçının prodüktörlüğünü üstlenen Ünal, kendi ismi altında
                “Cengiz Ünal ve Korosu” isimli bir koro albümü ve “Cengiz Ünal
                ve Vokal Kutusu” isimli akapella single çalışması yapmıştır.
                Aktif müzik hayatına Çekirdek Kadro ile devam eden yazar
                “Kimseyi Takmadan” isimli single çalışmayı da aynı grup ile
                birlikte yaptı. 2015’ten bu yana müzik terapi çalışmaları
                sürdürmektedir. Bağcılar Eğitim ve Araştırma Hastanesi Getat
                biriminden Müzik Terapi sertifikası alarak Müzik Terapistliği
                alanına girdi. Halen Mare Motivasyonda müzik direktörlüğüne,
                Çekirdek Kadro ile sahne çalışmalarına ve Müzik Terapi alanında
                da araştırmalarına devam etmektedir.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cardRoot: {
    width: "100%",
  },
  cardHeaderRight: {
    textAlign: "right",
  },
  cardHeaderLeft: {
    textAlign: "left",
  },
  media: {
    height: 440,
  },
}));
