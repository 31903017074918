export const LyricsData = [
  {
    id: "7",
    title: "Gelin Ayşe",
    subtitle: "Sivas - Çelebiler Köyü Türküsü",
    lines: [
      "Koyun gelir yata yata, çamurlara bata bata",
      "Koyun gelir yata yata, çamurlara bata bata",
      <br />,
      "Gelin Ayşem sele gitmiş ılgınlardan tuta tuta",
      "Gelin Ayşem sele gitmiş ılgınlardan tuta tuta",
      <br />,
      "Koyun gelir yozuyunan ayağının tozuyunan",
      "Koyun gelir yozuyunan ayağının tozuyunan",
      <br />,
      "Gelin Ayşem sele gitmiş çifte kuzuyunan",
      "Gelin Ayşem sele gitmiş çifte kuzuyunan",
    ],
    credits: [
      "Kaynak Kişi: Hasan Küpeli",
      "Derleyen: Muzaffer Sarısözen",
      "Düzenleme: Cengiz Ünal",
    ],
  },
  {
    id: "4",
    title: "Divane Aşık Gibi",
    subtitle: "",
    lines: [
      "Divane aşık gibi de dolanırım yollarda",
      "Kız senun sebebine kaldım İstanbullarda",
      <br />,
      "Baban beni babamdan da kir kerecuk istesun",
      "Allah’ın emru ile gelinim olsun desun",
      <br />,
      "Sar belune belune da Karadeniz kuşağı",
      "E kız sen de der misun alsam ha bu uşağı",
      <br />,
      "Yüksek dağın kuşuyum da selviye konacağum",
      "İste beni babamdan vermezse kaçacağum",
      <br />,
      "Al şalum yeşil şalum da dünyayı dolaşalum",
      "Sen yağmur ol ben bulut Maçka’da buluşalım",
    ],
    credits: ["Söz-Müzik: Hasan Tunç", "Düzenleme: Hakan Bağcı"],
  },
  {
    id: "2",
    title: "Bebeğin Beşiği Çamdan",
    subtitle: "Erzincan - Kemaliye - Eğin Türküsü",
    lines: [
      "Bebek beni deleyledi yaktı yıktı kül eyledi",
      "Her kapıya kul eyledi, nenni nenni a guzum nenni",
      <br />,
      "Nenni desem uyur mola gözler uyku bürür mola",
      "Yavrucuğum büyür mola, nenni nenni a guzum nenni",
      <br />,
      "Bebeğin beşiği çamdan düştü yuvarlandı damdan",
      "Bey babası gelir Şam’dan, nenni nenni a guzum nenni",
    ],
    credits: ["Kaynak Kişi: Mustafa Özgül", "Düzenleme: Cengiz Ünal"],
  },
  {
    id: "10",
    title: "Oy Asiye",
    subtitle: "Görele / Giresun Türküsü",
    lines: [
      "Ağasarın balını (da)",
      "Gel salını salını",
      "Adam cebunde taşır (da)",
      "Senin gibi gelini",
      <br />,
      "Oy sevduğum oy",
      "Oy Asiye Asiye",
      "Tütün koydum kesiye",
      "Anan seni veriyi de",
      <br />,
      "Bir bağa (evlek) pırasiye",
      "Oy sevduğum oy",
      "Sis dağının başında yel",
      "Püfur püfur esiyi",
      <br />,
      "Baban bu yıl kurbanı (da)",
      "Çifter çifter kesiyi",
      "Oy sevduğum oy",
      "Oy Asiye Asiye",
      <br />,
      "Tütün koydum kesiye",
      "Anan seni veriyi de",
      "Bir bağa (evlek) pırasiye",
      "Oy sevduğum oy",
    ],
    credits: ["Düzenleme: Hakan Bağcı"],
  },
  {
    id: "5",
    title: "Doşla Moma",
    subtitle: "Makedon Halk Türküsü",
    lines: [
      "Doşla moma na voda of aman",
      "Doşla moma na voda",
      "Doşla moma na voda aman uba vençayi",
      "Of aman aman aman aman",
      <br />,
      "Daymi moma voditsa of aman",
      "Daymi moma voditsa",
      "Daymi moma voditsa sosta bella rozitsa",
      "Of aman aman aman aman",
      <br />,
      "Yunak voda ne piyad of aman",
      "Yunak voda ne piyad",
      "Yunak voda ne piyad samo vino rakiya",
      "Of aman aman aman aman",
      <br />,
      "Aman of aman aman of",
      "Of aman aman of aman aman",
    ],
    credits: ["Düzenleme: Cengiz Ünal"],
  },
  {
    id: "3",
    title: "Bu Dünya Bir Pencere",
    subtitle: "Karadeniz Türküsü",
    lines: [
      "Dereler akar gider taşları yıkar gider",
      "Ey güzel taşları yıkar gider",
      <br />,
      "Bu dünya bir pencere her gelen bakar gider",
      "Ey güzel her gelen bakar gider",
      <br />,
      "Dereler akar bulanık köpüğünden alaluk",
      "Ey güzel köpüğünden alaluk",
      <br />,
      "Ha bu ışıklı dünya oldu bize karanluk",
      "Ey güzel oldu bize karanluk",
      <br />,
      "Gidelim deremene öğütelim unlari",
      "Ey güzel öğütelim unlari",
      <br />,
      "Güneşe çevirelim bu karanlık günleri",
      "Ey güzel bu karanlık günleri",
    ],
    credits: ["Kaynak Kişi: Sümeyra Çakır", "Düzenleme: Cengiz Ünal"],
  },
  {
    id: "6",
    title: "Eleno Kerko",
    subtitle: "Makedon Halk Şarkısı",
    lines: [
      "Eleno mammo eleno ti edna na mayka",
      "Şto stoiç kerko şto misliç, şto k›niga pişuvaş",
      <br />,
      "Pişuvam mayko pişuvam do gradot Edrene",
      "Edrene mayko Edrene, na moeto libe",
      <br />,
      "Da kupi mayko da kupi za mene kapela",
      "Kapela mayko kapela, od trista groşha",
    ],
    credits: ["Düzenleme: Cengiz Ünal"],
  },
  {
    id: "1",
    title: "Adige",
    subtitle: "Anonim Kafkas Ezgisi",
    lines: [
      "Kafkasya’da çiçek açar",
      "Asil bir Çerkes oynar",
      "Görmelisin, bilmelisin",
      "Kafkasya’da bir aşk var",
      <br />,
      "Toprağında destanlar var",
      "Hasreti aşka bağlar",
      "Yüreğinde insanların",
      "Sevgisi hasreti var",
    ],
    credits: ["Söz: Tülin Malkoç", "Düzenleme: Hakan Bağcı"],
  },
  {
    id: "12",
    title: "Yeni Biteyi",
    subtitle: "Karadeniz Türküsü",
    lines: [
      "Yeni biteyi yeni de",
      "Derelerin ormani",
      "Sevdaluktan kesildi",
      "Dizlerumun dermani",
      <br />,
      "Oy olmasa olmasa",
      "Bu sevdaluk olmasa",
      "Haçan oldi olacak da",
      "Seven seveni alsa",
      <br />,
      "Ayağımı ezeyi de",
      "Yeni yolun taşlari",
      "Yaktı yandırdı beni",
      "Gözleriyle kaşlari",
      <br />,
      "Bir duman aldı dağa da",
      "Bi de aldı denize",
      "Hayde desem sevduğum",
      "Gelecek misun bize",
    ],
    credits: ["Düzenleme: Hakan Bağcı"],
  },
  {
    id: "8",
    title: "Hasret Çektim",
    subtitle: "Doğu Türkistan Şarkısı",
    lines: [
      "Aydın ayrılalmaymen ay yüzünü körende",
      "Eymenip karalmaymen koşuman türülgende",
      "A... Hasret çektim A... yarni sigındım",
      <br />,
      "Koşuman türülgende rencigenmidin mendin",
      "İşencim sina kamilümit üzmedim sendin",
      "A... Hasret çektim A... yarni sigındım",
      <br />,
      "Men seni pana tatip ayvanında yataymu",
      "Altı künlük alemde seninde derdini yataymu",
    ],
    credits: [
      "Notaya Alan: Rahmi Oruç Güvenç",
      "Piyano Eşlik: Özcan Özbek",
      "Koro Düzenleme: Hakan Bağcı",
    ],
  },
  {
    id: "11",
    title: "Üç Kız Bir Ana",
    subtitle: "Kars - Sarıkamış Türküsü",
    lines: [
      "Yaylasından inmişler, inmişler aman",
      "Ağlarlar yana yana",
      "Üç kız bir ana, inmişler aman",
      "Ağlarlar yana yana",
      <br />,
      "Karaları giymişler, giymişler aman",
      "Ağlarlar yana yana",
      "Üç kız bir ana, giymişler aman",
      "Ağlarlar yana yana",
      <br />,
      "Sokuldum yanlarına, demezler aman",
      "Ağlarlar yana yana",
      "Üç kız bir ana, demezler aman",
      "Ağlarlar yana yana",
    ],
    credits: [
      "Kaynak Kişi: Kurbani Kılıç",
      "Derleyen: Muzaffer Sarısözen",
      "Düzenleme: Cengiz Ünal",
    ],
  },
  {
    id: "9",
    title: "Hey Onbeşli",
    subtitle: "Tokat Türküsü",
    lines: [
      "Hey onbeşli onbeşli",
      "Tokat yolları taşlı",
      "Onbeşliler geliyor",
      "Kızların gözü yaşlı",
      <br />,
      "Aslan yarim kız senin adın hediye",
      "Ben dolandım sen de dolan gel beriye",
      "Fistan aldım endazesi on yediye",
      <br />,
      "Giderim ilinizden",
      "Kurtulam dilinizden",
      "Yeşil bas ördek olsam",
      "Su içmem gölünüzden",
      <br />,
      "Aslan yarim kız senin adin hediye",
      "Ben dolandım sen de dolan gel beriye",
      "Fistan aldım endazesi on yediye",
      <br />,
      "Gidiyom gidemiyom",
      "Sevdim terk edemiyom",
      "Sevdigim pek gönüllü",
      "Gönlünü edemiyom",
      <br />,
      "Aslan yarim kız senin adin hediye",
      "Ben dolandım sen de dolan gel beriye",
      "Fistan aldım endazesi on yediye",
    ],
    credits: ["Düzenleme: Hakan Bağcı"],
  },
];
export default LyricsData;
