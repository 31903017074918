import React, { useState, useEffect, useContext } from "react";
import PlaybackEngine from "osmd-audio-player";
import PlayerContext from "../../context/playerContext";
import LyricCard from "../../components/lyricCard";
import InstructionCard from "../../components/InstructionCard";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Divider,
  Slider,
  IconButton,
  Drawer,
  Box,
  LinearProgress,
} from "@material-ui/core";
// * Icons
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AddIcon from "@material-ui/icons/Add";
import PauseIcon from "@material-ui/icons/Pause";
import RemoveIcon from "@material-ui/icons/Remove";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";

export default function PlayerControllerBar({ score, part, osmd }) {
  const bpmDelta = 1;
  const classes = useStyles();
  const playerContext = useContext(PlayerContext);
  const [player, setPlayer] = useState(new PlaybackEngine());
  const [ready, setReady] = useState(false);
  // BPM measure
  const [defaultBPM, setDefaultBPM] = useState(50);
  const [currentBPM, setCurrentBPM] = useState(10);
  const [currentMeasure, setCurrentMeasure] = useState(0);
  //Player States
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(true);
  const [stopped, setStopped] = useState(true);

  const [lyrDrawer, setLyrDrawer] = useState(false);
  const [instructionDrawer, setInstructionDrawer] = useState(false);

  // Drawer Lyrics
  const toggleLyrDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLyrDrawer(open);
  };
  // Drawer Instructions
  const toggleInstructionDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setInstructionDrawer(open);
  };
  // Play
  const handlePlay = () => {
    console.log("Play clicked");
    if (player.state === "STOPPED" || player.state === "PAUSED") {
      player.play();
      setPlaying(true);
      setPaused(false);
      setStopped(false);
      playerContext.onPlayingStatusChanged(true);
    } else {
      console.log("Already PLAYING");
    }
  };
  // Stop
  const handleStop = () => {
    console.log("Stop clicked");
    if (player.state === "PLAYING" || player.state === "PAUSED") {
      player.stop();
      player.cursor.show();
      setPlaying(false);
      setPaused(true);
      setStopped(true);
      setCurrentMeasure(0);
      playerContext.onPlayingStatusChanged(false);
    } else {
      console.log("Already STOPPED");
    }
  };
  // Pause
  const handlePause = () => {
    console.log("Pause clicked");
    if (player.state === "PLAYING") {
      player.pause();
      setPlaying(false);
      setPaused(true);
      setStopped(false);
      setCurrentMeasure(player.currentIterationStep);
      playerContext.onPlayingStatusChanged(false);
    } else {
      console.log("Already PAUSED");
    }
  };
  // BPM inc
  const handleBPMinc = () => {
    if (player.state === "PLAYING") {
      return;
    } else {
      const newBPM = player.playbackSettings.bpm + bpmDelta;
      player.setBpm(newBPM);
      console.log("BPM changed to > ", player.playbackSettings.bpm);
      setCurrentBPM(newBPM);
    }
  };
  // BPM dec
  const handleBPMdec = () => {
    if (player.state === "PLAYING") {
      return;
    } else {
      const newBPM = player.playbackSettings.bpm - bpmDelta;
      player.setBpm(newBPM);
      console.log("BPM changed to > ", player.playbackSettings.bpm);
      setCurrentBPM(newBPM);
    }
  };
  // BPM slider
  const handleBpmSliderChange = (event, newValue) => {
    if (player.state !== "PLAYING") {
      console.log("BPM slider changed");
      player.setBpm(newValue);
      setCurrentBPM(newValue);
    }
  };
  // BPM reset
  const handleResetBPM = () => {
    if (player.state !== "PLAYING") {
      const newBPM = defaultBPM;
      player.setBpm(newBPM);
      console.log(player.playbackSettings.bpm);
      setCurrentBPM(newBPM);
      console.log("BPM set to default");
    }
  };

  // Slider measure
  const handleMeasureSliderChange = (event, newValue) => {
    if (player.state !== "PLAYING") {
      console.log("Measure slider changed");
      player.jumpToStep(newValue);
      setCurrentMeasure(newValue);
    }
  };

  useEffect(() => {
    player.loadScore(osmd).then(() => {
      console.log(osmd);
      setDefaultBPM(player.playbackSettings.bpm);
      setCurrentBPM(player.playbackSettings.bpm);
      setReady(true);
    });
    return () => {
      setReady(false);
      setPlayer(null);
    };
  }, [osmd, player]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar disableGutters={false} variant="dense">
          {!ready && (
            <>
              <Box className={classes.loadPanelA}>
                <Typography
                  display="block"
                  align="center"
                  variant="h5"
                  color="primary"
                  // edge="start"
                >
                  panel yükleniyor
                </Typography>
              </Box>
              <Box className={classes.loadPanelB}>
                <LinearProgress variant="query" />
              </Box>
            </>
          )}
          {ready && (
            <>
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={handlePlay}
                color="primary"
                size="small"
                variant="contained"
                disabled={playing}
              >
                <PlayArrowIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                onClick={handlePause}
                size="small"
                color="primary"
                variant="contained"
                disabled={paused}
              >
                <PauseIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                edge="end"
                onClick={handleStop}
                color="primary"
                size="small"
                variant="contained"
                disabled={stopped}
              >
                <SkipPreviousIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography variant="body1" color="primary" edge="start">
                tempo
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <IconButton
                size="small"
                aria-label="menu"
                onClick={handleBPMdec}
                color="primary"
                variant="contained"
                disabled={playing}
              >
                <RemoveIcon />
              </IconButton>
              <Slider
                className={classes.slider}
                value={currentBPM}
                max={200}
                min={20}
                step={5}
                onChange={handleBpmSliderChange}
                disabled={playing}
                valueLabelDisplay="auto"
                aria-labelledby="discrete-slider-always"
              />
              <IconButton
                size="small"
                edge="end"
                aria-label="menu"
                onClick={handleBPMinc}
                color="primary"
                variant="contained"
                disabled={playing}
              >
                <AddIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography
                className={classes.bpmValue}
                variant="body1"
                color="primary"
              >
                {currentBPM}
              </Typography>
              <Typography
                className={classes.bpmValue}
                variant="body1"
                color="primary"
              >
                bpm
              </Typography>
              <IconButton
                size="small"
                edge="end"
                aria-label="menu"
                onClick={handleResetBPM}
                color="primary"
                variant="contained"
                disabled={playing}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography
                variant="body1"
                color="primary"
                edge="start"
                disabled={playing}
              >
                tarama
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <IconButton
                className={classes.noLink}
                size="small"
                color="primary"
                variant="contained"
                disabled={playing}
              >
                <FastRewindIcon />
              </IconButton>
              <Slider
                className={classes.slider}
                value={currentMeasure}
                max={player.iterationSteps}
                min={0}
                step={1}
                onChange={handleMeasureSliderChange}
                disabled={playing}
                aria-labelledby="discrete-slider-always"
              />
              <IconButton
                className={classes.noLink}
                size="small"
                color="primary"
                variant="contained"
                disabled={playing}
              >
                <FastForwardIcon />
              </IconButton>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                disabled={playing}
              />
              <Typography
                className={classes.head}
                variant="subtitle2"
                edge="start"
                color="primary"
              >
                ESER :
              </Typography>
              <Typography variant="body1" edge="start" color="primary">
                {score.title}
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography
                className={classes.head}
                variant="subtitle2"
                edge="start"
                color="primary"
              >
                PARTİSYON :
              </Typography>
              <Typography variant="body1" edge="middle" color="primary">
                {part.toLowerCase()}
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Button
                className={classes.btn}
                onClick={toggleLyrDrawer(true)}
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<MenuIcon />}
              >
                sözler
              </Button>
              <Button
                className={classes.btn}
                onClick={toggleInstructionDrawer(true)}
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<ErrorOutlineIcon />}
              >
                Notlar
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={lyrDrawer} onClose={toggleLyrDrawer(false)}>
        <Box>
          <Box
            className={classes.lyrics}
            onClick={toggleLyrDrawer(false)}
            onKeyDown={toggleLyrDrawer(false)}
            bg="default"
          >
            <CloseIcon fontSize="default" color="secondary" />
          </Box>
          <div className={classes.paperRoot}>
            <LyricCard id={score.id} />
          </div>
        </Box>
      </Drawer>

      <Drawer
        anchor="left"
        open={instructionDrawer}
        onClose={toggleInstructionDrawer(false)}
      >
        <Box>
          <Box
            className={classes.lyrics}
            onClick={toggleInstructionDrawer(false)}
            onKeyDown={toggleInstructionDrawer(false)}
            bg="default"
          >
            <CloseIcon fontSize="default" color="secondary" />
          </Box>
          <div className={classes.paperRoot}>
            <InstructionCard id={score.id} />
          </div>
        </Box>
      </Drawer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    marginBottom: theme.spacing(0),
  },
  slider: {
    width: 100,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  bpmValue: {
    width: "2.2rem",
  },
  noLink: {
    cursor: "default",
  },
  paperRoot: {
    padding: theme.spacing(2),
    width: 800,
  },
  lyrics: {
    backgroundColor: theme.palette.primary.main,
    height: 50,
    padding: theme.spacing(2),
    cursor: "pointer",
  },
  loadPanelA: {
    padding: theme.spacing(2),
    width: "20%",
    textAlign: "center",
  },
  loadPanelB: {
    padding: theme.spacing(2),
    width: "70%",
    textAlign: "center",
  },
  btn: {
    marginRight: theme.spacing(1),
  },
}));
