import React from "react";
import { Link } from "react-router-dom";

import { Container, Card, CardMedia, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/footer";

export default function Home() {
  const classes = useStyles();
  return (
    <>
      <main className={classes.main}>
        <Container className={classes.cardGrid} maxWidth="sm">
          <Box width={500} mx="auto">
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component={Link}
                to="/sayfa/onsoz"
                image="/images/cover.jpg"
                title="Çokesli Korolar için Halk Ezgileri -  Hakan BAĞCI & Cengiz ÜNAL (2020)"
              />
            </Card>
          </Box>
        </Container>
      </main>
      <Footer />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
  },
  card: {
    width: "100%",
  },
  cardMedia: {
    height: 700,
    width: 500,
    // paddingTop: "156.25%", // 16:9
    // paddingTop: "100%", // 16:9
    // paddingTop: "116%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#f2f4d1",
    padding: theme.spacing(6),
  },
}));
