import "./App.css";
import React, { useState } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PlayerContext from "./context/playerContext";

import Navbar from "./components/navbar";
import Home from "./pages/Home";
import Scores from "./pages/Scores";
import Score from "./pages/Scores/Score";
import Pages from "./pages/Pages";
import Lyrics from "./pages/Lyrics";

function App() {
  const [playing, setPlaying] = useState(false);
  const handlePlayingStatus = (playing) => {
    setPlaying(playing);
    console.log("Playing State change to: ", playing);
  };

  return (
    <div>
      <PlayerContext.Provider
        value={{
          playing: playing,
          onPlayingStatusChanged: handlePlayingStatus,
        }}
      >
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/sayfa/:id" component={Pages} />
            <Route exact path="/sozler/:id" component={Lyrics} />
            <Route exact path="/eserler" component={Scores} />
            <Route path="/eser/:id/:partition" component={Score} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </PlayerContext.Provider>
    </div>
  );
}

export default App;
