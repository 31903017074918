import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Grid } from "@material-ui/core/";

export default function Intro() {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.grid}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Koro Kültürü
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.body}>
          Çoksesli koro eğitimi ve yönetimine ilişkin çeşitli bilgiler, akademik
          olarak birçok makale ve kitapta bulunmaktadır. Ancak koro
          çalıştırırken ihtiyaç duyulan pratik bazı bilgilerin ne kadar önemli
          olduğunu özellikle genç şefler işin içine girince daha iyi
          anlamaktadır. Dijital devrimden sonra paylaşım araçlarının ve
          teknolojinin hızla değişmesi ve gelişmesi, birçok alanda olduğu gibi
          koro eğitimi ve yönetimi alanında da pozitif etkilere neden oldu. Biz
          de tecrübelerimizle oluşan birçok pratik ve güncel bilgileri sizlerle
          paylaşmaya karar verdik.
        </Typography>
        <Typography className={classes.body}>
          Koro birlikte yaşamın geliştirdiği en anlamlı etkinliklerden birisi
          belki de. Koro, sosyalleşmenin yaşandığı en güzel ortamlardan biridir.
          Enstrüman olmayan bir yerde hep birlikte müziksel bir etkinlik
          gerçekleştirmek isterseniz, insan sesi sayesinde elinizde büyük bir
          orkestra mevcuttur aslında. Her bir ses grubunun farklı ezgileri,
          farklı özellikleri, farklı davranışları, bireylere birlikte yaşamayı,
          birlikte hareket edebilmeyi, müziksel işbirliğini, demokrasiyi en iyi
          anlatan, öğreten öğelerdir. Başkaları konuşurken onları iyi
          dinlemenin, onları susturmadan, bastırmadan bir şey söylemenin
          ifadesidir koro. Bu durumda, koristler bakımından ve seyirci
          bakımından düşünüldüğünde koro şefinin müzik eğitiminin ötesinde bir
          davranış eğitimi gerçekleştirdiği anlaşılacaktır.
        </Typography>
        <Typography className={classes.body}>
          Koro bir gönül işidir. Sevgi ile beslenir. Müzik insanlara evrensel
          anlamda güzel mesajlar verir. Müziğin verdiği mesajlar iyi insan ve
          doğru insan olma yolundadır. Bu mesajlara örnekler verilecek olursa
          sevgi, dostluk, savaşların ve ayrılıkların verdiği acılar,
          mücadelenin, çalışmanın, temiz olmanın önemi, birlikte bir şey
          yapabilme duygusu, kültürel olarak yanlış bulunan davranışlar,… gibi
          pek çok konuyu içerdiği görülür. Yani toplumu formal ya da informal
          yoldan eğitmenin en güzel yollarından birisidir korolar. Çünkü müzik,
          diğer eğitim alanlarından farklı olarak, aynı zamanda duygulara hitap
          eder. Eğlenirken, hüzünlenirken yapılan bir eğitim, sırf bilgi
          aktarımı ile kıyaslandığında daha kalıcı sonuçlar ortaya
          çıkarmaktadır.
        </Typography>
        <Typography className={classes.body}>
          Amatör olarak çalışmalarına devam eden birçok çoksesli koro üyesinin
          tek hedefi sadece müzik icra etmek olmayabilir. Koristler işlerinden
          vakit ayırarak geldikleri bu korolara insanlarla iletişime geçmek,
          paylaşmak, başarmak, nitelikli zaman geçirmek vb. duygularla
          katılırlar. Hatta bazı koristler için çok seslilik son derece sıradan
          bile olabilir. Bazı koristler için koroya katılma sebebi olarak bu ve
          benzeri motivasyon yönelimli kazanımlar, müzikal deneyime bağlı
          kazanımlardan daha önemlidir. Bu ilk duyuluşunda çoksesliliğe emek
          veren insanlar için pek hoş karşılanmasa da aslında koroları bir arada
          tutan önemli bir özelliktir. Bir koro şefinin bunu bilerek
          çalışmalarına devam etmesi, koristlerin sosyal ve psikolojik
          ihtiyaçlarının da göz önünde bulundurması koroyu pozitif yönde
          etkileyecektir.
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
