import React, { useEffect, useRef, useState } from "react";
import { OpenSheetMusicDisplay as OSMD } from "opensheetmusicdisplay";
import PlayerController from "./PlayerControllerBar";
import ScoreData from "../../data/scoresData";
import { osmdOptions as OSMDoptions } from "../../data/osmdOptions";
import { Backdrop, Container, Typography } from "@material-ui/core";

export default function ScoreDisplay({ id, partition }) {
  // Ref
  const refContainer = useRef();
  // States
  const [Osmd, setOsmd] = useState(null);
  const [selectedScore, setSelectedScore] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const selectedScore = ScoreData.find((s) => s.id === id);
    setSelectedScore(selectedScore);
    console.log(id, partition);

    const fileUrl = (i, p) => {
      const selected =
        selectedScore.filenameBase + "_" + partition + ".musicxml";
      return "/scores/" + selected;
    };

    // Data source
    const file = fileUrl(id, partition);
    console.log(file);
    // Loading...
    const osmd = new OSMD(refContainer.current, OSMDoptions);
    setOsmd(osmd);

    osmd
      .load(file)
      .then(() => {
        osmd.render();
        setLoaded(true);
        console.log("Score file Loaded Rendered");
      })
      .catch((e) => {
        console.log("OSMD LOADING Error occured...", e);
      });
  }, [id, partition]);

  return (
    <>
      <Container maxWidth="xl">
        <div ref={refContainer} />
        {!loaded && (
          <div>
            <Backdrop
              open={!loaded}
              style={{
                backgroundColor: "#ccc",
                display: "flex",
                zIndex: 9,
                color: "#fff",
              }}
            >
              <Typography variant="h3" align="center" color="initial">
                yükleniyor...
              </Typography>
            </Backdrop>
          </div>
        )}

        {loaded && (
          <PlayerController
            score={selectedScore}
            part={partition}
            osmd={Osmd}
          />
        )}
      </Container>
    </>
  );
}
