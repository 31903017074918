import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Grid } from "@material-ui/core/";

export default function Preface() {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.grid}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Önsöz
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.body}>
          Birlikte şarkı söylemek deyince aklımıza her müzik türünden tek sesli
          eserler gelirken, koro deyince ise genellikle çoksesli şarkılar ve
          çoksesli korolar gelmektedir. Günümüzde tüm dünyada olduğu gibi,
          ülkemizde de koro eğitimi, koro çalışmaları büyük topluluklar
          tarafından önemsenir hale gelmiştir. Sosyal ve kültürel çalışmaların,
          etkinliklerin büyük bir bölümünü müzik, özellikle de koro etkinlikleri
          oluşturur haldedir.
        </Typography>
        <Typography className={classes.body}>
          Ülkemizin her şehrinde okullarda, çeşitli kurumların desteğiyle veya
          tamamen kişisel çabalarla çeşitli korolar kurulmaktadır. Bu korolar,
          farklı müzik türleri ve farklı yaş gruplarının dahil olduğu çeşitli
          tek sesli ve çoksesli korolar olarak çeşitlenmektedir. Bu koroların
          çalışmaları ve konserlerinde kullanılan dağarcıklar ile ilgili
          olanaklar birbirinden farklılıklar göstermektedir. Örneğin Türk Sanat
          Müziği ve Türk Halk Müziği koroları repertuar açısından bir sorun
          yaşamamaktadır. Ancak çoksesli müziğe gönül vermiş koro severler ve
          şefler açısından düşünüldüğünde büyük bir repertuar sorunu olduğu,
          Türk ezgilerine ilişkin çoksesli düzenlemeler ve özgün eserlerin
          yeterli sayıda olmadığı gözlemlenmektedir.
        </Typography>
        <Typography className={classes.body}>
          Çoksesli korolarda her bir ses grubu tıpkı orkestradaki her bir çalgı
          grubu gibi farklı görevler üstlenir. Burada ana ezgiyi hangi ses
          grubunun vereceği, ana ezgi dışında eşlik eden her bir ses grubunun
          nasıl eşlik edeceği, hangi seslerle eşlik edeceğinin iyi bir biçimde
          düşünülmesi, tasarlanması gerekmektedir.
        </Typography>
        <Typography className={classes.body}>
          Çoksesli müziklerin özellikle de Türkçe repertuar açısından sınırlı
          olduğu göze çarpmaktadır. Şefler korolarına çalıştıracak çoksesli eser
          bulmakta güçlük çekmektedir. Bu yüzden korolarda tek yönlü bir
          repertuar oluşmakta, şefler aynı repertuar ile uzun süreler çalışmak
          zorunda kalmaktadır.
        </Typography>
        <Typography className={classes.body}>
          Çoksesli müzik alanında ülkemizdeki yayınlar incelendiğinde, bu konuda
          her türlü alanda yayınlara ihtiyaç duyulduğu anlaşılmaktadır. Bu
          bağlamda yerel, etnik ve uluslararası türlerden eser düzenlemelerine
          olduğu kadar, özgün bestelere de ihtiyaç duyulmaktadır.
        </Typography>
        <Typography className={classes.body}>
          Elinizdeki kitap böyle bir ihtiyaç hissedilerek oluşturuldu. Kitap,
          bir kitap serisinin ilk kitabı olarak düşünülmüştür. Ardından korolar
          ile ilgili olarak çeşitli farklı alanlarda da kitaplar oluşturmayı,
          yayınlamayı hedeflemekteyiz. Çokseslilik çalışmaları için küçük bir
          katkı olmasını diliyoruz.
        </Typography>
        <Typography className={classes.body}>
          Bu konuda bizi yüreklendiren ve cesaretlendiren başta ailelerimize,
          yetişmemizde emeği geçen sevgili öğretmenlerimiz Özcan Özbek, Yücel
          Elmas, Gülsevin Doğanay, Ekrem ve Rabia Ünal, Besim Akkuş, Mustafa
          Tügen ve Gökçen Koray’a, kitap için fikirleri ve yardımlarıyla
          katkılarını bizden esirgemeyen Suna Çevik Hocamıza ve dostlarımız
          Kubilay Can ve Behzat Cem Günenç’e teşekkürü borç biliriz. Faydalı
          olması ve iyi günlerde çalışılması dileğiyle...
        </Typography>
        <Divider className={classes.divider} />
        <Typography
          className={classes.bodyFooter}
          variant="subtitle1"
          align="right"
        >
          Hakan BAĞCI & Cengiz ÜNAL
        </Typography>
        <Typography
          className={classes.bodyFooter}
          align="right"
          variant="subtitle2"
        >
          Şubat 2020, İstanbul
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: theme.spacing(3),
  },
  bodyFooter: {},
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
