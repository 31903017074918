import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Grid } from "@material-ui/core/";

export default function Intro() {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.grid}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Genç Koro Eğitimcilerine Öneriler
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.body}>
          Koro oluştururken ses gruplarını titizlikle seçmek gerekir. Soprano,
          alto, tenor ve bas partileri için seçeceğiniz koristlerin ses
          özelliklerini büyük bir dikkatle dinleyin. Koristlerin uygun olmayan
          ses gruplarına alınması bireysel olarak kişiye zorluk yaşatırken,
          bütünsel olarak bakıldığında da koronun tınısının yetersiz olmasına
          veya iyi olmamasına sebep olur.
        </Typography>
        <Typography className={classes.body}>
          Korist seçerken bir önemli nokta da koristlerin işitsel
          yetenekleridir. Alınacak koristlerin istenilen sesleri çıkarmakta ve
          farklı tonlara uyum sağlamakta yetenekli, duyarlı olmasına özen
          göstermekte fayda var. Bu konuda çok kötü olarak tabir edilebilecek
          bir kulak veya yeteneğe sahip koristler, seslerin koroda da temiz
          tınlamasının önünde engel teşkil edecektir. Bu noktada koro şefinin
          eğitim anlayışı çok büyük önem taşımaktadır. Bazı kişilerin işitsel
          yeteneklerini az miktarda sergileyebildiği görülebilir. Bu kişileri
          koroya kabul etmek ya da etmemek koro şefinin inisiyatifindedir. Bu
          bireylerin bir kısmı eğitildiğinde çok büyük bir aşama kat
          edebilmektedir. Bunun için yapılan tercih, eğitim ya da profesyonellik
          arasındaki tercih anlamına gelecektir.
        </Typography>
        <Typography className={classes.body}>
          Koro şefinin bir eseri çalıştırmadan önce, eserin notalarını
          incelemesi gerekir. Eserin koronun ses sınırlarına uygunluğunun gözden
          geçirilmesi önemlidir. Çalıştırılacak ezgilerin ve ezgilere ait
          sözlerin tanınması parçanın öğretimini kolaylaştıracaktır. Ayrıca şef,
          partitürü inceledikten sonra kendi partitürü üzerinde hangi partide
          hangi ezgilerin ön plana çıkması gerektiği, nüans işaretleri ve hız
          işaretlerini işaretlemesi eserin yönetimi için hatırlatıcı bir
          kolaylık olabilir.
        </Typography>
        <Typography className={classes.body}>
          Bir başka önemli nokta da eser üzerinde yazan sözlerin telaffuzudur.
          Anlam ve tını bakımından telaffuz biçimi önem taşımaktadır. Bu sorun
          özellikle yabancı koro edebiyatından eserler seslendirilirken
          görülmektedir. Eserin seslendirilmesi için bir dil uzmanından yardım
          alınması faydalı görülmektedir. Dil konusunda uzman birinin yapılacak
          telaffuzları şefe açıklaması, eserin yorumunu dinlemesi hataların
          azaltılması bakımından faydalıdır. Kullanılan dil ile ilgili bir uzman
          bulunamadığı durumlarda ise eseri seslendirmek zorunlu ise telaffuz
          için bir dil birliğinin sağlanması esas alınmalıdır.
        </Typography>
        <Typography className={classes.body}>
          Koro çalışmalarına başlarken vücut ve ses bakımından bireylerin
          bedensel olarak rahatlaması ve ısınmasının sağlanması gereklidir.
          Fiziksel olarak kasların ve vücudun rahatlatılması, ses çıkarmak için
          kullanılan organların da rahat kullanımını sağlayacaktır. Nefes
          alıştırmaları, eserlerde nefesin etkin kullanımını ve ses açma
          egzersizlerinin uygulanması da sesin çıkarılması konusunda daha
          başarılı olunmasını sağlayacaktır.
        </Typography>
        <Typography className={classes.body}>
          Gündelik hayatımızın akışı içinde zaman çok önemli bir olgu haline
          gelmiştir. Kaybedilen zaman büyük bir kayıp olarak görülmelidir. Çünkü
          zamanı kaybetmek yapılacak işlerin gerçekleştirilmesine engel
          olmaktadır. Bu yüzden zamanın verimli kullanılması önemlidir. Koronun
          çalışması için ayrılan sürenin çalışma ve ara verme sıklıkları ile
          birlikte iyi planlanması gerekmektedir.
        </Typography>
        <Typography className={classes.body}>
          Koroya eseri çalışmadan önce dağıtmak, profesyonel bir koro ise
          müziksel bilgiye sahip olanların eseri çalışmasını sağlamak eserin
          seslendirilmesini kolaylaştıracaktır. Teknolojik imkanların
          kullanılması başka kolaylıklar da sağlamaktadır. Özellikle amatör
          korolarda şefin, eserin çalışılması için eserlere ait midi dosyasını
          veya ses kaydını koristlere sağlaması faydalı olmaktadır. Günümüz
          koşullarında çoğu kişinin, hemen hemen herkesin akıllı telefonu
          mevcuttur. Bu sayede herkesin kendine özgü müzik çalar bir aygıtı da
          olmaktadır. Bu sayede koristler yolda giderken, seyahat ederken bile
          eser çalışabilirler. Koristlerin, koro şefinin ödev olarak verdiği
          midi veya ses dosyasını akıllı telefonu ile dinlemesi, çalışması
          sonucunda, kendi ses kaydını veya koreografi çalışmasının video
          görüntüsünü koro şefine göndermesi, toplu çalışma için bir araya
          gelinen zamanın daha verimli bir şekilde kullanılmasına neden
          olabilir.
        </Typography>
        <Typography className={classes.body}>
          Şef, daima koronun iyi bir eğitim almasını amaçlamalıdır. Müzik
          teorisi, kulak eğitimi, solfej ya da bir başka isimle anılırsa
          müziksel işitme, okuma ve yazma eğitimi şefin işlerini kolaylaştıracak
          önemli müzik alanı etkinlikleridir. Dersin küçük bir bölümünü bu
          etkinliklere ayırmak, koristlerin derslerde hazır bulunurluk düzeyini
          zaman içinde arttıracaktır. Bu eğitim yanında, koristlere müzik
          teorisi soruları sormak ve küçük müziksel işitme değerlendirmeleri
          yapmak koristlerin müziksel becerilerinin artışı için etken olabilir.
        </Typography>
        <Typography className={classes.body}>
          Çalışılacak partilerin sağlıklı çalışılması için her bir ses grubundan
          grup şefleri belirlemek, fayda sağlayacaktır. Mümkünse bu kişilerin
          müzikten anlayan, kulağı iyi kişilerden seçilmesi uygundur. Grup
          şefleri, seslendirdikleri partilerin ses kayıtlarını grup arkadaşları
          ile paylaşabilir, onları çalıştırabilir. İşlerini iyi yapan
          yardımcıların müzikten anlayan, kulağı iyi kişilerden oluşmasının yanı
          sıra gönüllülerden oluşan yardımcıların müziği iyi bilmese de veya
          kulağı çok iyi olmasa da verimli ve yeterli yardımcılar olabildikleri
          gözlenmiştir. İstekli ve inançlı olmak, kısacası bu işi sevmek yeterli
          olabilmektedir. Bu yüzden koristler için çeşitli olanaklar yaratmak ve
          onların motivasyonlarını yüksek tutmanın öneminin daima akılda
          tutulmasının olumlu sonuçlar doğuracağı düşünülmektedir.
        </Typography>
        <Typography className={classes.body}>
          Her koro belirli bir repertuar bütünlüğü gözetilerek
          çalıştırılmalıdır. Tek yönlü yapılan repertuar seçimi, koronun zamanla
          monoton bir yapı içinde kalmasına neden olabilir. Yıllık çalışmalar
          içinde repertuar seçiminin, her müzik türünden (koraller, madrigaller,
          marşlar, halk ezgileri düzenlemeler, ulusal veya uluslar arası çeşitli
          eserler,… gibi) çeşitli örnekler dahil edilerek yapılması
          önerilmektedir.
        </Typography>
        <Typography className={classes.body}>
          Müzik doğası gereği icra edilen bir sanattır. Müzik icra edenler için
          sahne deneyimi paylaşılmaz, anlatılmaz bir duygudur. Kısaca betimlemek
          gerekirse, sahne deneyimi bireye bir şeyi başarabilme, güçlükle baş
          edebilme, takdir edilme, birlikte iş başarabilme gibi birçok yönden
          olumlu etki yapar. Bu da bireylerde heyecan ve haz yaratır. Koronun
          büyük ya da küçük, uzun ya da kısa süreli de olsa performans
          göstermesi, konserler vermesi sağlanmalıdır. Hatta yurt içi ve yurt
          dışındaki konser ve festival hedefleri de koronun insan kaynağındaki
          etkisini artırır. Bu tüm korolar için tüm zamanlarda etkili olan
          motivasyonel bir unsurdur.
        </Typography>
        <Typography className={classes.body}>
          Çoksesli müziğe gönül vermiş koro şeflerinin karşılaşabileceği bir
          güçlük de karşılarında her zaman çoksesli eser icra edebilecek bir
          koronun çıkamayacağı gerçeğidir. Bazen koro yönetimi ile ilgili bir
          gruptan, dernek veya vakıftan talep gelebilir. Karşılaşılan ekip ya da
          koro istekli olabilir fakat çok sesli eser seslendirmek amatörler için
          bazen uzun bir yoldur. Bu yolda başarıya 3 - 5 provada ulaşmak pek
          mümkün görünmüyor ise şeflerin koronun motivasyonunu artıracak, basit,
          çabuk sonuç alınabilecek tek sesli eserler, 2 sesli eserler veya 3-4
          gruplu kanonlar ile repertuar zenginliği oluşturması faydalı
          olacaktır. Koronun, bu yolun uzun ve adım adım yürünen bir yol olduğu
          ve zamanla başarının artacağı konusunda bilgilendirilmesi, koristlerin
          motivasyonlarının sağlanması ve başarı için etken olabilir.
        </Typography>
        <Typography className={classes.body}>
          Bunların yanı sıra bir koro şefinin müzisyenlik ile ilgili
          becerilerinin iyi düzeyde olması beklenir. Piyano çalabilme, eşlik
          edebilme, uygun eser bulunamadığı durumlar için düzenleme ya da
          besteleme yapabilme becerilerinin olması şef için önemlidir. Örneğin
          bazı durumlarda şefin 4 sesli bir eseri 2 ya da 3 sese indirebilmesi
          beklenebilir. Bunun için müzik teorisi bilgilerini, armoni kurallarını
          iyi bilmesi ve basit çokseslilik kurallarını etkin ve yerinde
          kullanabilmesi önemlidir.
        </Typography>
        <Typography className={classes.body}>
          Koristler için belki de her şeyden önemlisi motivasyon konusudur. Bir
          koro şefinin korosunun motivasyon nabzını her zaman çok iyi
          tutabilmesi beklenir. Söylediği, kullandığı sözcük ve cümlelerle
          pozitif bir ortam ve havayı oluşturabilmesi konusunda kendisini
          geliştirmesi uygundur. Gerektiğinde koro repertuarında olmasa bile, o
          dönemde bilinen ve sevilen bir eseri çok seslendirerek korosuna
          söyletmesi, sinerjinin artması ve bu yolla başarının artmasına neden
          olabilir.
        </Typography>
        <Typography className={classes.body}>
          Son olarak söylenebilecek ne olabilir diye düşündüğümüzde, işin
          başlangıç noktasına dönüyoruz. Yani bizi koroya yaklaştıran o duyguya,
          “sevgi”ye… Kim olursak olalım, eğer çoksesli bir koro yönetiyorsak, bu
          işi sevdiğimizdendir. Kimse zorla bulunduğu noktaya gelemez, hele
          sanat alanında bu pek mümkün değil. Farkında olmasa da bu eğlenceli ve
          bir o kadar da etkili zihin ve beden aktivitesini icra etmek ve
          yaymaya çalışmak her koro şefinin hedefidir. Bu ilk itici gücü
          unutmamanızı dileriz. Bazen hayat bizi zorlu mücadelelere, kariyer
          savaşlarına veya sürekli tekrarların bulunduğu kıyılara
          sürükleyebilir. Bu durumlarda dahi samimiyetle müziği keşfettiğimiz o
          günleri anımsamalıyız. Hatalarımızdan beslendiğimizi bilerek, işimizi
          öğrenci samimiyeti ve öğretmen disiplini ve alçak gönüllülük ile
          yürütmeliyiz. Koro, her anlamda farklı öğelerin bir araya gelmesinden
          oluşan bir olgudur. Farklı kültürler, farklı eserler, farklı kişiler,
          farklı sesler, farklı ezgiler bir araya gelerek koronun kültürel ve
          tınısal yapısını oluşturur. Biz farklılıkların tınısından
          hoşlanıyoruz…
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
