import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Typography,
  // Divider,
  List,
  ListItem,
  ListItemText,
  Card,
  // ListItemIcon,
  ListItemAvatar,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// Pages
import InstructionData from "../data/instructionData";
//
export default function InstructionCard({ id }) {
  const classes = useStyles();
  const track = InstructionData.find((track) => track.id === id);

  return (
    <>
      <CssBaseline />
      <Typography
        className={classes.title}
        variant="h5"
        gutterBottom
        align="center"
      >
        {track.title}
      </Typography>
      <Typography align="center" className={classes.title} variant="subtitle2">
        {track.subtitle.length > 1 ? `"${track.subtitle}"` : null}
      </Typography>
      <List className={classes.list}>
        {track.lines.map((b, index) => (
          <ListItem key={index} alignItems="center" dense>
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <Card className={classes.listCard} raised>
              <ListItemText className={classes.line}>
                <Typography
                  className={classes.line}
                  variant="body1"
                  component="p"
                  align="left"
                >
                  {b}
                </Typography>
              </ListItemText>
            </Card>
          </ListItem>
        ))}
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  list: {
    width: "90%",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  listCard: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
  },
  line: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "20%",
    margin: "auto",
  },
}));
