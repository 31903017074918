import React from "react";
import { Link } from "react-router-dom";
import {
  Fields as fields,
  Headings as headings,
  Partitions as partitions,
} from "../data/scoresData";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";

export default function AccordionCard({ details, parts }) {
  const classes = useStyles();

  return (
    <Card raised className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            {fields.map(
              (f, index) =>
                details.hasOwnProperty(f) && (
                  <div key={index + f}>
                    <Typography
                      className={
                        headings[index].length > 0
                          ? "classes.head"
                          : "classes.noHead"
                      }
                      display="inline"
                      color="textPrimary"
                      variant="body2"
                    >
                      {headings[index]}
                    </Typography>
                    <Typography
                      display="inline"
                      color="textSecondary"
                      variant="body2"
                    >
                      {details[f]}
                    </Typography>
                  </div>
                )
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {partitions.map(
              (p) =>
                parts.includes(p) && (
                  <Button
                    size="small"
                    fullWidth
                    component={Link}
                    to={`/eser/${details.id}/${p}`}
                    key={details.id + p}
                    variant="contained"
                    color="default"
                  >
                    {p}
                  </Button>
                )
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={`/eser/${details.id}/partitur`}
          key={details.id + "partitur"}
          variant="contained"
          color="primary"
        >
          partitür
        </Button>
        <Button
          size="small"
          component={Link}
          to={`/sozler/${details.id}`}
          key={details.id + "sozler"}
          variant="outlined"
          color="default"
        >
          SÖZLER
        </Button>
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  tag: {},
  cardContent: {},
  head: {
    marginRight: theme.spacing(2),
  },
  noHead: {},
}));
