import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Typography, Divider } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Pages
import LyricsData from "../data/lyricsData";
//
export default function LyricCard({ id }) {
  const classes = useStyles();
  const track = LyricsData.find((track) => track.id === id);

  return (
    <>
      <CssBaseline />
      <Typography
        className={classes.title}
        variant="h5"
        gutterBottom
        align="center"
      >
        {track.title}
      </Typography>
      <Typography align="center" className={classes.title} variant="subtitle2">
        {track.subtitle.length > 1 ? `"${track.subtitle}"` : null}
      </Typography>

      <List>
        {track.credits.map((c, index) => (
          <ListItem key={index} className={classes.credits}>
            <ListItemText className={classes.credits}>
              <Typography variant="caption" component="p" align="right">
                {c}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <Divider className={classes.divider} variant="inset" />

      <List>
        {track.lines.map((b, index) => (
          <ListItem key={index} alignItems="center" dense>
            <ListItemText className={classes.line}>
              <Typography
                className={classes.line}
                variant="body1"
                component="p"
                align="center"
              >
                {b}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <Divider className={classes.divider} variant="inset" />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  line: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  credits: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "20%",
    margin: "auto",
  },
}));
