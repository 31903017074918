import React from "react";
import {
  ScoreData as data,
  ScorePartitionSpread as spread,
} from "../data/scoresData";
import { makeStyles } from "@material-ui/core/styles";

import {
  CssBaseline,
  Typography,
  Grid,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionCard from "../components/accordionCard";

export default function Scores() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getSpread = (i) => {
    return spread.find((s) => s.id === i).partitions;
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid container>
          <div className={classes.root}>
            <Typography className={classes.title} variant="h5" gutterBottom>
              Eserler
            </Typography>
            {data.map((d) => (
              <Accordion
                className={classes.acc}
                expanded={expanded === `panel${d.id}`}
                onChange={handleChange(`panel${d.id}`)}
                key={d.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id={d.id}
                  key={d.id}
                >
                  <Typography className={classes.heading}>{d.title}</Typography>
                  <Typography className={classes.secondaryHeading}>
                    {d.subtitle}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accDetails} key={d.id}>
                  <AccordionCard
                    className={classes.accCard}
                    key={d.id}
                    details={d}
                    parts={getSpread(d.id)}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Grid>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  acc: {
    margin: theme.spacing(0),
    padding: 0,
  },
  accCard: {
    padding: 0,
  },
  accDetails: {
    padding: 0,
  },
}));
