import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, CssBaseline } from "@material-ui/core";

// Pages
import Intro from "./Intro";
import Culture from "./Culture";
import Preface from "./Preface";
import Teachers from "./Teachers";
import Authors from "./Authors";
//
export default function Pages() {
  const classes = useStyles();
  const { id: page } = useParams();
  const pages = [
    {
      url: "sunus",
      component: <Intro />,
    },
    {
      url: "onsoz",
      component: <Preface />,
    },
    {
      url: "koro-kulturu",
      component: <Culture />,
    },
    {
      url: "genc-koro-egitimcilerine-oneriler",
      component: <Teachers />,
    },
    {
      url: "yazarlar",
      component: <Authors />,
    },
  ];

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <main>
          <Grid container spacing={3} className={classes.mainGrid}>
            {pages.find((p) => p.url === page).component}
          </Grid>
        </main>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));
