export const InstructionData = [
  {
    id: "1",
    title: "Adige",
    subtitle: "Anonim Kafkas Ezgisi",
    lines: [],
  },
  {
    id: "2",
    title: "Bebeğin Beşiği Çamdan",
    subtitle: "Erzincan - Kemaliye - Eğin Türküsü",
    lines: [
      "Puandorg süreleri şefin isteği doğrultusunda değişkenlik gösterebilir.",
      "Akorların yarattığı duygulara göre puandorg sürelerine karar verilebilir.",
      "Eserin temposu baştan sona serbest olduğu için yorumda şefin payı çok büyük.",
      "Fermatalar farklı uzunluklarda olabilir.",
      "9. ölçünün sonundaki staccato benim yorumum değil. Kaynak kişiden alınan karakteristik bir artikülasyon.",
      "Nüansların dalgalı olması ve piyanodan forteye ilerleyen hareketler esere yakışabilir.",
    ],
  },
  {
    id: "3",
    title: "Bu Dünya Bir Pencere",
    subtitle: "Karadeniz Türküsü",
    lines: [
      "Esere koroda ritme yetenekli birisi bir perküsyon ile eşlik edebilir.",
      "Bendir, Arbane, Kajon, Djembe vb bir enstrüman olabilir.",
      "İlk 4 ölçüde veya eserin girişinden önce Alto ve Sopranolar Re sesi ile pedal(dem) tutabilir.",
      "30. ölçüdeki son nota puandorg ile uzatılabilir.",
      "30. ölçüden sonra başa dönülebilir ve 31. ölçü son tekrardan sonra kullanılabilir.",
      'Her tekrarda 30. ölçüden sonra sadece Bas partisi ey güzel diyerek başa dönebilir ve son tekrarda hep beraber "ey güzel" diyebilir.',
      "Son 2 ölçünün icrası şefin veya koronun isteği doğrultusunda değiştirilebilir.",
      "Yukarıdaki çeşitlemeler farklı tekrarlar sırasında kullanılabilir.",
    ],
  },
  {
    id: "4",
    title: "Divane Aşık Gibi",
    subtitle: "",
    lines: [],
  },
  {
    id: "5",
    title: "Doşla Moma",
    subtitle: "Makedon Halk Türküsü",
    lines: [
      "8. ölçüdeki ‘’of aman’’ şefin yorumuna bağlı olarak tempoda kırılma yaratacak şekilde olabilir.",
      "İstenirse kodada biraz tempo artabilir",
    ],
  },
  {
    id: "6",
    title: "Eleno Kerko",
    subtitle: "Makedon Halk Şarkısı",
    lines: [
      "Kapalı ağız söylenen yerler çeşitli kombinasyonlarla renklendirilebilir. Örn. ilk iki ölçü soprano solo girer sonra tüm koro eklenir vs.",
      "Söz kısmında da solo söylemek isteyen solistlerden soprano grubunun susup o partiyi solo olarak bir koristin söylemesi organize edilebilir.",
    ],
  },
  {
    id: "7",
    title: "Gelin Ayşe",
    subtitle: "Sivas - Çelebiler Köyü Türküsü",
    lines: [
      "Eser piyanodan forteye giden ve tekrar piyanoya geri dönen dalgalanmalardan oluşuyor. Bu tercih edilmezse daha sade ve istendiği gibi bir versiyonu da yapilabilir.",
    ],
  },
  {
    id: "8",
    title: "Hasret Çektim",
    subtitle: "Doğu Türkistan Şarkısı",
    lines: [],
  },
  {
    id: "9",
    title: "Hey Onbeşli",
    subtitle: "Tokat Türküsü",
    lines: [],
  },
  {
    id: "10",
    title: "Oy Asiye",
    subtitle: "Görele / Giresun Türküsü",
    lines: [
      '21. ve 29. ölçüler arası, giriş melodisi veya çıkış melodisi olarak "Mmm" veya "U" vokali ile seslendirilebilir.',
    ],
  },
  {
    id: "11",
    title: "Üç Kız Bir Ana",
    subtitle: "Kars - Sarıkamış Türküsü",
    lines: [
      "Piyano çok nüans katmadan akıcı ve yumuşak bir çalım ile eşlik edebilir.",
      "Bitişteki fermata sadece bitişte uygulanır ve 2 ölçü önceden ritardando ile başlar.",
    ],
  },
  {
    id: "12",
    title: "Yeni Biteyi",
    subtitle: "Karadeniz Türküsü",
    lines: [],
  },
];
export default InstructionData;
