import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Card, CardContent, Divider } from "@material-ui/core"

export default function SimpleCard() {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<CardContent>
				<Divider className={classes.divider} />
				<Typography className={classes.title} color="textSecondary">
					web tasarım & programlama
				</Typography>
				<div className={classes.blockindent}>
					<Typography variant="body2" component="p">
						neoPAST
					</Typography>
					<Typography variant="caption" component="p">
						<a className={classes.link} href="mailto:bilgi@neopast.com" target="_blank" rel="noreferrer noopener">
							bilgi@neopast.com
						</a>
						<br />
						<a className={classes.link} href="http://www.neopast.com" target="_blank" rel="noreferrer noopener">
							www.neopast.com
						</a>
					</Typography>
					<Typography variant="body2" component="p">
						&copy; 2021
					</Typography>
				</div>
			</CardContent>
		</Card>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 175,
	},
	title: {
		marginTop: theme.spacing(1),
	},
	pos: {
		marginTop: 12,
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	link: {
		textDecoration: "none",
		color: "#8a8b8b",
	},
	blockindent: {
		paddingLeft: theme.spacing(4),
	},
}))
