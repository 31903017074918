import { AppBar, Box, Toolbar, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export default function Navbar() {
	const classes = useStyles()
	return (
		<>
			<AppBar position="fixed" color="default" className={classes.appBar}>
				<Toolbar color="default">
					<Box className={classes.titleBox} display="flex" width="100%" alignItems="center" justifyContent="center">
						<Typography className={classes.title} color="textSecondary" variant="body1">
							yazarlar :
						</Typography>
						<Typography className={classes.titleSpace} variant="body2" component="p" display="inline">
							Hakan BAĞCI & Cengiz ÜNAL
						</Typography>
						<Typography className={classes.title} color="textSecondary" variant="body1">
							dağıtım :
						</Typography>
						<Typography className={classes.titleSpace} variant="body2" component="p">
							<a className={classes.link} href="http://www.akademisyen.com" target="_blank" rel="noreferrer noopener">
								www.akademisyen.com
							</a>
						</Typography>
						<Typography className={classes.titleSpace} color="textSecondary" variant="body2">
							ISBN 978-605-258-979-3
						</Typography>
						<Typography className={classes.title} color="textSecondary" variant="body2">
							BISAC CODE MUS051000
						</Typography>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		top: "auto",
		bottom: 0,
	},
	divider: {
		margin: 5,
	},
	title: {
		margin: theme.spacing(0),
	},
	link: {
		textDecoration: "none",
		color: "#8a8b8b",
	},
	titleSpace: {
		marginRight: theme.spacing(2),
	},
}))
