export const ScoreData = [
	{
		id: "1",
		title: "Adige",
		subtitle: "Kafkas Ezgisi",
		arranger: "Hakan Bağcı",
		tag: "İstanbul - 2008",
		lyrics: "Tülin Malkoç",
		filenameBase: "Adige",
	},
	{
		id: "2",
		title: "Bebeğin Beşiği Çamdan",
		subtitle: "Erzincan - Kemaliye - Eğin Türküsü",
		source: "Mustafa Özgül",
		arranger: "Cengiz Ünal",
		tag: "İstanbul - 1994",
		filenameBase: "Bebegin_Besigi_Camdan",
	},
	{
		id: "3",
		title: "Bu Dünya Bir Pencere",
		subtitle: "Karadeniz Türküsü",
		source: "Sümeyra Çakır",
		arranger: "Cengiz Ünal",
		tag: "İstanbul - 2004",
		filenameBase: "Bu_Dunya_Bir_Pencere",
	},
	{
		id: "4",
		title: "Divane Aşık Gibi",
		subtitle: "Hasan Tunç",
		arranger: "Hakan Bağcı",
		tag: "İstanbul - 1999",
		lyrics: "Hasan Tunç",
		song: "Hasan Tunç",
		filenameBase: "Divane_Asik_Gibi",
	},
	{
		id: "5",
		title: "Doşla Moma Na Voda",
		subtitle: "Makedon Halk Türküsü",
		arranger: "Cengiz Ünal",
		tag: "İstanbul - 2001",
		filenameBase: "Dosla_Moma",
	},
	{
		id: "6",
		title: "Eleno Kerko",
		subtitle: "Makedon Halk Şarkısı",
		arranger: "Cengiz Ünal",
		tag: "İstanbul - 2001",
		filenameBase: "Elona_Kerko",
	},
	{
		id: "7",
		title: "Gelin Ayşe",
		subtitle: "Sivas - Çelebiler Köyü Türküsü",
		source: "Hasan Küpeli",
		compiler: "Muzaffer Sarısözen",
		arranger: "Cengiz Ünal",
		tag: "Niksar, Tokat - 1994",
		filenameBase: "Gelin_Ayse",
	},
	{
		id: "8",
		title: "Hasret Çektim",
		subtitle: "Doğu Türkistan Ezgisi",
		tag: "İstanbul - 1991/2018",
		piano: "Özcan Özbek",
		arrangerChoir: "Hakan Bağcı",
		scoring: "Rahmi Oruç Güvenç",
		filenameBase: "Hasret_Cektim",
	},
	{
		id: "9",
		title: "Hey Onbeşli",
		subtitle: "Tokat Türküsü",
		arranger: "Hakan Bağcı",
		tag: "İstanbul - 2017",
		filenameBase: "Hey_Onbesli",
	},
	{
		id: "10",
		title: "Oy Asiye",
		subtitle: "Giresun Türküsü",
		arranger: "Hakan Bağcı",
		tag: "İstanbul - 2012",
		filenameBase: "Oy_Asiye",
	},
	{
		id: "11",
		title: "Üç Kız Bir Ana",
		subtitle: "Kars - Sarıkamış Türküsü",
		source: "Kurbani Kılıç",
		compiler: "Muzaffer Sarısözen",
		arranger: "Cengiz Ünal",
		tag: "Antalya - 1992",
		filenameBase: "Uc_Kiz_Bir_Ana",
	},
	{
		id: "12",
		title: "Yeni Biteyi",
		subtitle: "Karadeniz Türküsü",
		arranger: "Hakan Bağcı",
		tag: "İstanbul- 2019",
		filenameBase: "Yeni_Biteyi",
	},
]

export const ScorePartitionSpread = [
	{ id: "1", partitions: ["soprano", "alto", "tenor", "bas", "piyano"] },
	{ id: "2", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "3", partitions: ["soprano", "alto", "tenor", "bas", "solo"] },
	{ id: "4", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "5", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "6", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "7", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "8", partitions: ["soprano", "alto", "tenor", "bas", "piyano"] },
	{ id: "9", partitions: ["soprano", "alto", "tenor", "bas", "piyano"] },
	{ id: "10", partitions: ["soprano", "alto", "tenor", "bas"] },
	{ id: "11", partitions: ["soprano", "alto", "tenor", "bas", "piyano"] },
	{ id: "12", partitions: ["soprano", "alto", "tenor", "bas", "piyano"] },
]

export const Fields = ["source", "compiler", "arranger", "lyrics", "song", "piano", "arrangerChoir", "scoring", "tag"]

export const Headings = [
	"kaynak kişi :",
	"derleyen :",
	"düzenleme :",
	"söz :",
	"müzik :",
	"piayno :",
	"koro düzenleme :",
	"notaya alan :",
	"",
]

export const Partitions = ["soprano", "alto", "tenor", "bas", "partitur", "solo", "piyano"]

export function getPartitions(id) {
	const parts = ScorePartitionSpread.find((sp) => sp.id === id).partitions
	return parts
}

export default ScoreData
