import React from "react";
import { useParams } from "react-router-dom";
import { Container, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LyricCard from "../components/lyricCard";

export default function Lyrics() {
  const { id } = useParams();
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md" className={classes.root}>
        <LyricCard id={id} />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
}));
