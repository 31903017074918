import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import PlayerContext from "../context/playerContext"

import {
	AppBar,
	Box,
	Button,
	Toolbar,
	Typography,
	IconButton,
	Paper,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import QueueMusicIcon from "@material-ui/icons/QueueMusic"
import CreditsCard from "./creditsCard"
import CreditsCardNeopast from "./creditsCardNeopast"

export default function Navbar() {
	// Styles
	const classes = useStyles()
	// states
	const [state, setState] = useState(false)
	// context
	const playerContext = useContext(PlayerContext)

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return
		}
		setState(open)
	}

	return (
		<>
			<Drawer open={state} onClose={toggleDrawer(false)}>
				<div className={classes.list} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
					<div className={classes.paperRoot}>
						<Paper elevation={3} className={classes.paper}>
							<Typography className={classes.sideMenuHead} variant="h5" align="center">
								<Button to="/">Çoksesli Korolar için Halk Ezgileri</Button>
							</Typography>
						</Paper>
					</div>
					<List disablePadding={true} dense={true}>
						<ListItem button component={Link} to="/">
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Giriş" />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem button component={Link} to="/sayfa/sunus">
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Sunuş" />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem button component={Link} to="/sayfa/onsoz" divider>
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Önsöz" />
						</ListItem>
						<ListItem button component={Link} to="/sayfa/koro-kulturu">
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Koro kültürü" />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem button component={Link} to="/sayfa/genc-koro-egitimcilerine-oneriler">
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Genç Koro Eğitimcilerine Öneriler" />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem button component={Link} to="/sayfa/yazarlar" divider>
							<ListItemIcon>
								<DoubleArrowIcon />
							</ListItemIcon>
							<ListItemText primary="Yazarlar Kakkında" />
						</ListItem>
						<ListItem button component={Link} to="/eserler" divider>
							<ListItemIcon>
								<QueueMusicIcon />
							</ListItemIcon>
							<ListItemText primary="Eserler" />
						</ListItem>
					</List>
					<CreditsCard />
					<CreditsCardNeopast />
				</div>
			</Drawer>
			<AppBar position="sticky" color="default">
				<Toolbar color="default">
					<IconButton
						disabled={playerContext.playing}
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={toggleDrawer(true)}
					>
						{playerContext.playing ? <PlayArrowIcon /> : <MenuIcon />}
					</IconButton>
					<Box
						className={classes.titleBox}
						display="flex"
						width="100%"
						// height={80}
						alignItems="center"
						justifyContent="center"
					>
						{!playerContext.playing ? (
							<Typography className={classes.logoTitle} variant="h4" component={Link} to="/">
								Çoksesli Korolar için Halk Ezgileri
							</Typography>
						) : (
							<Typography className={classes.logoTitleNoLink} variant="h4">
								Çoksesli Korolar için Halk Ezgileri
							</Typography>
						)}
					</Box>
				</Toolbar>
			</AppBar>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	titleRight: {
		flexGrow: 1,
		marginRight: theme.spacing(0),
		width: "30%",
	},
	title: {
		marginRight: theme.spacing(0),
	},
	link: {
		textDecoration: "none",
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		hover: true,
	},
	list: {
		width: 500,
	},
	paperRoot: {
		padding: theme.spacing(2),
	},
	sideMenuHead: {
		padding: theme.spacing(2),
	},
	sideFooter: {
		padding: theme.spacing(2),
	},
	logo: {
		width: "100%",
	},
	logoTitle: {
		fontFamily: '"raleway", sans-serif',
		textDecoration: "none",
		"&:hover": {
			textDecoration: "none",
			cursor: "pointer",
		},
		"&:visited": {
			textDecoration: "none",
			cursor: "pointer",
		},
		"&:active": {
			textDecoration: "none",
			cursor: "pointer",
		},
	},
	logoTitleNoLink: {
		fontFamily: '"raleway", sans-serif',
		textDecoration: "none",
		"&:hover": {
			textDecoration: "none",
		},
		"&:visited": {
			textDecoration: "none",
		},
		"&:active": {
			textDecoration: "none",
		},
	},
	blockindent: {
		paddingLeft: theme.spacing(4),
	},
	titleBox: {},
}))
