// import React from "react";
import { Typography, Divider, Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

export default function Intro() {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.grid}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Sunuş
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.body}>
          Müzik Eğitimcileri Hakan Bağcı ve Cengiz Ünal “Çoksesli Korolar İçin
          Halk Ezgileri” adlı 12 düzenleme ile koro yazınımıza ve dolayısıyla
          müzik eğitimine 2020 yılında yeni ve değerli bir katkı sundular.
        </Typography>
        <Typography className={classes.body}>
          Bu çalışmada, farklı coğrafyaların kültür öğelerini, dilsel
          özelliklerini, estetiğini yansıtan anonim ve özgün türkülerin koro
          düzenlemeleri yer almaktadır. Çalışmanın özünde etnik, yerel ve ulusal
          kültürlere özgü duyuş, düşünüş ve görüşlerin öne çıkarıldığı çok
          kültürlülük – çeşitli kültürlülük (multiculturalisme) anlayışının
          benimsendiği görülmektedir.
        </Typography>
        <Typography className={classes.body}>
          Koro müziğinde özellikle dilin kullanılması sebebiyle, ulusal ve yerel
          dil öğelerinin ve estetiğinin etkileri somut olarak hissedilmekte ve
          geniş kitlelerce daha kolay anlaşılmaktadır. Koro için yapılan her
          türkü düzenlemesi, adeta yeni bir kompozisyondur. Bu nedenle, türkü
          ile çok iyi bağdaşan ses kümelerinin bir araya getirilmesi
          yaratıcılığın bir sonucudur.
        </Typography>
        <Typography className={classes.body}>
          Öte yandan, koro müziği edebiyatına, sanatın doğasında var olan ve
          durmadan gelişen çağdaş müzik dillerini ve tekniklerini göz ardı
          etmeyen, yeniliklere ve uluslararası etkileşimlere açık, özgün
          eserlerle katkıda bulunarak dünya sanatında biz de varız
          diyebilmeliyiz. Bu bağlamda önce ulusal kültür değerlerimizden
          esinlenen çağdaş ve özgün yaratılarla yeni şeyler söylemenin, yeni
          anlatım dilleri oluşturmanın gereğine inanmaktayım.
        </Typography>
        <Typography className={classes.body} gutterBottom>
          Değerli müzik eğitimcileri Hakan Bağcı ve Cengiz Ünal’ın “Çoksesli
          Korolar İçin Halk Ezgileri” adlı albümünde yer alan türküler partisyon
          olarak incelendiğinde, her bir partinin, ses genişliği, ses
          aralıkları, ritmik ve tonal, modal yapı bakımından kolay öğrenilebilir
          özellikte olduğu görülmektedir. Gerek seçilen türküler, gerekse renkli
          vokal tınılar içeren koro düzenlemeleri, bu özellikleriyle Güzel
          Sanatlar Liseleri’nin koroları ile ergenlik ve gençlik korolarının
          eğitiminde, eğitim materyalleri olarak kullanılabilecektir. Bu döneme
          özgü repertuvarın sınırlı sayıda olduğu düşünüldüğünde bu albüm,
          önemli bir boşluğu dolduracak ve koro müziği dağarını
          zenginleştirecektir. Koro müziğine katkılarından dolayı değerli
          eğitimcileri kutlar, koro şeflerine, bu yalın, saf ve duygu yüklü halk
          ezgilerinin koro düzenlemelerini, konser programlarına alarak,
          sevgiyle, bilgiyle, zevkle ve coşkuyla seslendirmelerini öneririm.
        </Typography>
        <Typography variant="body2" align="right" gutterBottom>
          Prof. Suna ÇEVİK
        </Typography>
        <Typography variant="body2" align="right">
          18 Mart 2020
        </Typography>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
