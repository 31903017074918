import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Card, CardContent, Divider } from "@material-ui/core"

export default function SimpleCard() {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography className={classes.title} color="textSecondary" gutterBottom>
					yazarlar
				</Typography>
				<div className={classes.blockindent}>
					<Typography variant="body2" component="p">
						Hakan BAĞCI
					</Typography>
					<Typography variant="caption" component="p">
						<a className={classes.link} href="http://www.hakanbagci.com" target="_blank" rel="noreferrer noopener">
							www.hakanbagci.com
						</a>

						<br />
						<br />
					</Typography>
					<Typography variant="body2" component="p">
						Cengiz ÜNAL
					</Typography>
					<Typography variant="caption" component="p">
						<a className={classes.link} href="http://www.cengizunal.com.tr" target="_blank" rel="noreferrer noopener">
							www.cengizunal.com.tr
						</a>
					</Typography>
				</div>

				<Divider className={classes.divider} />
				<Typography className={classes.title} color="textSecondary" gutterBottom>
					dağıtım
				</Typography>
				<div className={classes.blockindent}>
					<Typography variant="body2" component="p">
						Akademisyen Kitabevi A.Ş.
					</Typography>
					<Typography variant="body2" component="p">
						Halk Sokak 5 / A Yenişehir / Ankara
						<br />
						<br />
						Tel: 0312 431 16 33
					</Typography>
					<Typography variant="caption" component="p">
						<a className={classes.link} href="mailto:siparis@akademisyen.com" target="_blank" rel="noreferrer noopener">
							siparis@akademisyen.com
						</a>
						<br />
						<a className={classes.link} href="http://www.akademisyen.com" target="_blank" rel="noreferrer noopener">
							www.akademisyen.com
						</a>
					</Typography>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.title} color="textSecondary" variant="body2">
					ISBN 978-605-258-979-3
					<br />
					BISAC CODE MUS051000
				</Typography>
			</CardContent>
		</Card>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 175,
	},
	title: {
		marginTop: theme.spacing(1),
	},
	pos: {
		marginTop: 12,
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	link: {
		textDecoration: "none",
		color: "#8a8b8b",
	},
	blockindent: {
		paddingLeft: theme.spacing(4),
	},
}))
