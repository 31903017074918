export const osmdOptions = {
  autoResize: true,
  followCursor: true,
  disableCursor: false,
  drawComposer: true,
  drawCredits: true,
  measureNumberInterval: 1,
  // drawingParameters: "compacttight",
  drawMetronomeMarks: true,
  //renderSingleHorizontalStaffline: true,
};
